<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 병원 -->
          <c-select
            :comboItems="comboItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hospitalName"
            label="병원"
            v-model="searchParam.hospitalName"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <q-btn-group flat class="col-3">
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon="arrow_back_ios" label="이전달" @click="onPrev" />
            <q-btn color="grey-8" class="calMoveBtn" outline rounded :label="title" style="width: 200px" @click="onToday"/>
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon-right="arrow_forward_ios" label="다음달" @click="onNext" />
          </q-btn-group>
        </div>
      </template>
    </c-search-box>
    <!-- 임시용(전체로) v-on:click -->
    <div v-on:click="linkClick">
      <q-calendar
        ref="calendar"
        v-model="selectedDate"
        view="month"
        locale="ko-kr"
        :day-height="140"
        @change="onChange"
        show-work-weeks
      >
        <template #week="{ week }">
          <template v-for="(computedEvent, index) in getWeekEvents(week)">
            <q-badge
              :key="index"
              class="q-row-event"
              :class="badgeClasses(computedEvent, 'day')"
              :style="badgeStyles(computedEvent, 'day', week.length)"
              @click="openReserveDetail(week, index)"
            >
              <template v-if="computedEvent.event">
                <!-- {{computedEvent.event}} -->
                <q-icon :name="computedEvent.event.icon" class="q-mr-xs"></q-icon>
                <span class="ellipsis">{{ computedEvent.event.title }}</span>
              </template>
            </q-badge>
          </template>
        </template>
      </q-calendar>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
import QCalendarm from '@quasar/quasar-ui-qcalendar'
import {QCalendar} from '@quasar/quasar-ui-qcalendar'
import "@quasar/quasar-ui-qcalendar/dist/index.css"

const CURRENT_DAY = new Date()

function getCurrentDay (day) {
  const newDay = new Date(CURRENT_DAY)
  newDay.setDate(day)
  const tm = QCalendarm.parseDate(newDay)
  return tm.date
}

export default {
  name: 'outernal-manage',
  components: {
    QCalendar,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        dept1: '',
        dept2: '',
        hospitalName: '1'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        width: '80%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      comboItems:[
        {
          code:"1",
          codeName:"삼성서울병원"
        },
        {
          code:"2",
          codeName:"부산대병원"
        },
        {
          code:"3",
          codeName:"서울대병원"
        },
      ],
      editable: true,
      listUrl: '',
      title: '',
      dateFormatter: undefined,
      start: undefined,
      selectedDate: '',
      row: null,
      weekEvents: [],
      events: [
        {
          title: '이길동',
          color: 'orange',
          start: getCurrentDay(1),
          end: getCurrentDay(1),
          icon: 'person'
        },
        {
          title: '김지연',
          color: 'orange',
          start: getCurrentDay(1),
          end: getCurrentDay(1),
          icon: 'person'
        },
        {
          title: '유지현',
          color: 'orange',
          start: getCurrentDay(1),
          end: getCurrentDay(1),
          icon: 'person'
        },
        {
          title: '양승혜',
          color: 'orange',
          start: getCurrentDay(1),
          end: getCurrentDay(1),
          icon: 'person'
        },
        {
          title: '지승원',
          color: 'orange',
          start: getCurrentDay(1),
          end: getCurrentDay(1),
          icon: 'person'
        },
        {
          title: '홍길동',
          color: 'blue',
          start: getCurrentDay(5),
          end: getCurrentDay(5),
          icon: 'person'
        },
        {
          title: '김철수',
          color: 'yellow',
          start: getCurrentDay(9),
          end: getCurrentDay(9),
          icon: 'person'
        },
        {
          title: '이민수',
          color: 'green',
          start: getCurrentDay(14),
          end: getCurrentDay(14),
          icon: 'person'
        },
        {
          title: '윤민수',
          color: 'purple',
          start: getCurrentDay(19),
          end: getCurrentDay(19),
          icon: 'person'
        },
        {
          title: '김승민',
          color: 'grey',
          start: getCurrentDay(22),
          end: getCurrentDay(22),
          icon: 'person'
        },
        {
          title: '장길동',
          color: 'grey',
          start: getCurrentDay(22),
          end: getCurrentDay(22),
          icon: 'person'
        },
        {
          title: '정홍길',
          color: 'black',
          start: getCurrentDay(27),
          end: getCurrentDay(27),
          icon: 'person'
        },
        {
          title: '이길동',
          color: 'blue',
          start: getCurrentDay(31),
          end: getCurrentDay(31),
          icon: 'person'
        },
        {
          title: '이호미',
          color: 'blue',
          start: getCurrentDay(31),
          end: getCurrentDay(31),
          icon: 'person'
        },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    onChange ({ start }) {
      this.start = start
      this.updateTitle()
    },
    onPrev () {
      this.$refs.calendar.prev()
    },
    onNext () {
      this.$refs.calendar.next()
    },
    onToday() {
      this.$refs.calendar.moveToToday()
    },
    updateTitle () {
      this.title = this.start.year + '년 ' + this.start.month + '월'
    },
    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },

    badgeClasses (infoEvent, type) {
      const color = infoEvent.event !== undefined ? infoEvent.event.color : 'transparent'
      const cssColor = this.isCssColor(color)
      const isHeader = type === 'header'

      return {
        [`text-white bg-${color}`]: !cssColor,
        'full-width': !isHeader && (!infoEvent.side || infoEvent.side === 'full'),
        'left-side': !isHeader && infoEvent.side === 'left',
        'right-side': !isHeader && infoEvent.side === 'right',
        // 'cursor-pointer': infoEvent.event !== undefined,
        'q-day-event-void': infoEvent.event === undefined // height: 0, padding: 0
      }
    },

    badgeStyles (infoEvent, type, weekLength, timeStartPos, timeDurationHeight) {
      const s = {}
      if (timeStartPos) {
        s.top = timeStartPos(infoEvent.event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(infoEvent.event.duration) + 'px'
      }
      if (infoEvent.size !== undefined) {
        s.width = ((100 / weekLength) * infoEvent.size) + '% !important'
      }
      // s['align-items'] = 'flex-start'
      return s
    },

    getWeekEvents (week) {
      const tsFirstDay = QCalendarm.parsed(week[0].date + ' 00:00')
      const tsLastDay = QCalendarm.parsed(week[week.length - 1].date + ' 23:59')
      const firstDay = QCalendarm.getDayIdentifier(tsFirstDay)
      const lastDay = QCalendarm.getDayIdentifier(tsLastDay)

      const eventsWeek = []
      this.events.forEach((event, id) => {
        const tsStartDate = QCalendarm.parsed(event.start + ' 00:00')
        const tsEndDate = QCalendarm.parsed(event.end + ' 23:59')
        const startDate = QCalendarm.getDayIdentifier(tsStartDate)
        const endDate = QCalendarm.getDayIdentifier(tsEndDate)

        if (this.isBetweenDatesWeek(startDate, endDate, firstDay, lastDay)) {
          const left = QCalendarm.daysBetween(tsFirstDay, tsStartDate, true)
          const right = QCalendarm.daysBetween(tsEndDate, tsLastDay, true)

          eventsWeek.push({
            id, // index event
            left, // Position initial day [0-6]
            right, // Number days available
            size: week.length - (left + right), // Size current event (in days)
            event // Info
          })
        }
      })

      const events = []
      if (eventsWeek.length > 0) {
        const infoWeek = eventsWeek.sort((a, b) => a.left - b.left)
        infoWeek.forEach((event, i) => {
          this.insertEvent(events, week.length, infoWeek, i, 0, 0)
        })
      }

      return events
    },

    insertEvent (events, weekLength, infoWeek, index, availableDays, level) {
      const iEvent = infoWeek[index]
      if (iEvent !== undefined && iEvent.left >= availableDays) {
        // If you have space available, more events are placed
        if (iEvent.left - availableDays) {
          // It is filled with empty events
          events.push({ size: iEvent.left - availableDays })
        }
        // The event is built
        events.push({ size: iEvent.size, event: iEvent.event })

        if (level !== 0) {
          // If it goes into recursion, then the item is deleted
          infoWeek.splice(index, 1)
        }

        const currentAvailableDays = iEvent.left + iEvent.size

        if (currentAvailableDays < weekLength) {
          const indexNextEvent = QCalendarm.indexOf(infoWeek, e => e.id !== iEvent.id && e.left >= currentAvailableDays)

          this.insertEvent(
            events,
            weekLength,
            infoWeek,
            indexNextEvent !== -1 ? indexNextEvent : index,
            currentAvailableDays,
            level + 1
          )
        } // else: There are no more days available, end of iteration
      }
      else {
        events.push({ size: weekLength - availableDays })
        // end of iteration
      }
    },

    isBetweenDates (date, start, end) {
      return date >= start && date <= end
    },

    isBetweenDatesWeek (dateStart, dateEnd, weekStart, weekEnd) {
      return (
        (dateEnd < weekEnd && dateEnd >= weekStart) ||
          dateEnd === weekEnd ||
          (dateEnd > weekEnd && dateStart <= weekEnd)
      )
    },
    linkClick(item) {
      this.popupOptions.title = '인바디 예약하기'; // 
      this.popupOptions.param = {
        item: item,
      };
      this.popupOptions.target = () => import(`${'./inbodyDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    openReserveDetail(item) {
      this.popupOptions.title = '인바디 예약하기'; // 
      this.popupOptions.param = {
        item: item,
      };
      this.popupOptions.target = () => import(`${'./inbodyDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
